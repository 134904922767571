import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.requiresAuth(req)) {
            return next.handle(req.clone({ headers: this.headers, url: environment.apiUrl + req.url }));
        }
        return next.handle(req.clone(req.clone({ url: environment.apiUrl + req.url })));
    }

    public get headers(): HttpHeaders {
        let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        const authService = this.injector.get(AuthService);
        const auth = authService.getAuthFromLocalStorage();
        if (auth && auth.authToken) {
            headers = headers.append('Authorization', 'Bearer ' + auth.authToken);
        }
        return headers;
    }

    private requiresAuth(req: HttpRequest<any>): boolean {
        const excludedUrls: string[] = ['/oauth/token'];

        return !excludedUrls.includes(req.url);
    }
}
