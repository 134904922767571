import { NgModule, APP_INITIALIZER, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { AuthInterceptor } from './modules/auth/services/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SyncService } from './common/services/sync.service';

function appInitializer(authService: AuthService, syncService: SyncService) {
  return () => {
    return new Promise<void>((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(() => {
        syncService.initSync();
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2').then(({ default: swal }) => swal.mixin({
        buttonsStyling: false,
        icon: 'warning',
        customClass: {
          popup: 'bg-light',
          closeButton: 'btn btn-light btn-lg',
          confirmButton: 'btn btn-light-primary btn-lg',
          denyButton: 'btn btn-light-danger btn-lg',
          cancelButton: 'btn btn-light btn-lg',
        }
      }))
    }),
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, SyncService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
