import { Injectable } from '@angular/core';
import { PosAuthenticationQueryParams, QueryParamsInterface } from '../interfaces/query-params.interface';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { RestaurantModel } from '../models/restaurant.model';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';
import { AuthModel, EncryptedAuthModel } from '../models/auth.model';
import { StringHelper } from '../helpers/string.helper';
import { HttpClient } from '@angular/common/http';
import { CURRENCIES, CurrencyType } from '../models/globals';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  private _authorizationsStorageKey = `auth-${environment.appVersion}-${environment.STORAGE_KEY}`;
  private _restaurantdataStorageKey = `restaurant-${environment.appVersion}-${environment.STORAGE_KEY}`;

  private _restaurantSubject: BehaviorSubject<RestaurantModel> = new BehaviorSubject<any>(undefined);

  private static _currency?: string;

  constructor(
    private api: HttpClient
  ) {
  }

  public static getCurrencyConfig(): CurrencyType {
    const currencyCode = PosService.getCurrency();
    return CURRENCIES.find((currency) => currency.code === currencyCode) || {};
  }

  loadRestaurant(queryParams: QueryParamsInterface = {}): Observable<RestaurantModel> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'menu.products.is_available,menu.children.products.is_available,menu.products.order_types,menu.children.products.order_types,menu.products.addons,menu.children.products.addons,tables.waiter,address,order_types.payment_methods,logo,coupons,table_locations,floorplans.floorplan_objects.table';
    // @ts-ignore
    return this.api.get('/pos/restaurant', { params: queryParams }).pipe(
      map((response: any) => {
        return new RestaurantModel(response);
      }),
      tap((restaurant: RestaurantModel) => {
        restaurant.fetchedAt = new Date();
        this.initRestaurant(restaurant);
      })
    );
  }

  loadAuthentications(queryParams: PosAuthenticationQueryParams = {}): Observable<EncryptedAuthModel[]> {
    // @ts-ignore
    return this.api.get('/pos/authorizations', { params: queryParams }).pipe(
      map((response: any) => {
        return response.map((value: EncryptedAuthModel) => new EncryptedAuthModel(value));
      }),
      tap((auths: EncryptedAuthModel[]) => {
        this.initAuthentications(auths);
      })
    );
  }

  getRestaurant(): Observable<RestaurantModel> {
    return this.getRestaurantSubject().asObservable();
  }

  getRestaurantSubject(): BehaviorSubject<RestaurantModel> {
    if (this._restaurantSubject.value) {
      return this._restaurantSubject;
    }

    const restaurant = this.getRestaurantFromStorage();
    this.setCurrency(restaurant.currency);
    this._restaurantSubject.next(restaurant);

    return this._restaurantSubject;
  }

  setRestaurantSubject(restaurant: RestaurantModel) {
    this._restaurantSubject.next(restaurant);
  }

  getRestaurantSnapshot(): RestaurantModel {
    if (this.getRestaurantSubject().value) {
      return this.getRestaurantSubject().value;
    }

    const restaurant = this.getRestaurantFromStorage();
    this.setCurrency(restaurant.currency);
    this.setRestaurantSubject(restaurant);

    return this.getRestaurantSubject().value;
  }

  getEmployees(): UserModel[] {
    return this.getEmployeeAuthentications()?.map((auth: EncryptedAuthModel) => auth.user) || [];
  }

  getEmployeeAuthByPasscode(passkey: string): AuthModel | undefined {
    return this.findDecryptedAuthModelByPasskey(passkey);
  }

  getEmployeeByPasscode(passkey: string): UserModel | undefined {
    return this.findEmployeeModelByPasskey(passkey);
  }

  removeStorageData() {
    localStorage.removeItem(this._authorizationsStorageKey);
    localStorage.removeItem(this._restaurantdataStorageKey);
  }

  getEmployeeAuthentications(): EncryptedAuthModel[] {
    return this.getAuthenticationsFromStorage();
  }

  private initRestaurant(restaurant: RestaurantModel) {
    this.setCurrency(restaurant.currency);
    this.setRestaurantSubject(restaurant);
    this.setRestaurantInStorage(restaurant);
  }

  private initAuthentications(auths: EncryptedAuthModel[]) {
    this.setAuthenticationsInStorage(auths);
  }

  private setRestaurantInStorage(restaurant: RestaurantModel) {
    localStorage.setItem(this._restaurantdataStorageKey, JSON.stringify(restaurant));
  }

  private getRestaurantFromStorage() {
    const restaurantData = JSON.parse(localStorage.getItem(this._restaurantdataStorageKey) || '');

    return new RestaurantModel(restaurantData);
  }

  private setAuthenticationsInStorage(auths: EncryptedAuthModel[]) {
    localStorage.setItem(this._authorizationsStorageKey, JSON.stringify(auths.map((auth: EncryptedAuthModel) => auth.toApi())));
  }

  private getAuthenticationsFromStorage() {
    const authDataJson = localStorage.getItem(this._authorizationsStorageKey);
    const authData = authDataJson ? JSON.parse(authDataJson) : [];

    return authData.map((auth: EncryptedAuthModel) => new EncryptedAuthModel(auth)) || [];
  }

  private setCurrency(currency?: string) {
    PosService._currency = currency;
  }

  private static getCurrency() {
    return PosService._currency || environment.appCurrency;
  }

  private findEmployeeEncryptedAuthByPasscode(passcode: string): EncryptedAuthModel | undefined {
    for (const encryptedAuth of this.getAuthenticationsFromStorage()) {
      const decrypedPasscode = StringHelper.decrypt(encryptedAuth.passcode, passcode);
      if (decrypedPasscode == passcode) {
        return encryptedAuth;
      }
    }
    return undefined;
  }

  private findDecryptedAuthModelByPasskey(passcode: string): AuthModel | undefined {
    const encryptedAuth = this.findEmployeeEncryptedAuthByPasscode(passcode);
    return encryptedAuth?.getDecryptedAuthModel(passcode) || undefined;
  }

  private findEmployeeModelByPasskey(passcode: string): UserModel | undefined {
    return this.findEmployeeEncryptedAuthByPasscode(passcode)?.user || undefined;
  }
}
