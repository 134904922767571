import { Injectable } from '@angular/core';
import { from, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrderModel } from '../models/order.model';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToasterService } from './toaster.service';
import { OrderFilterInterface } from '../interfaces/query-params.interface';
import { OnlineService } from './online.service';
import { OfflineStorageService } from './offline-storage.service';
import { PaginatedDataInterface } from '../interfaces/pagination.interface';
import { ResponseHelper } from '../helpers/response.helper';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private _ordersFilterStorageKey = `orders-filter-${environment.appVersion}-${environment.STORAGE_KEY}`;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private onlineService: OnlineService,
    private offlineStorage: OfflineStorageService
  ) {
  }

  private getOrdersWithHeaders(queryParams: any): any {
    queryParams.expand = queryParams.expand || 'masterPayment.installments,order_items.addons.product_addon_id,coupon,table,customer';
    return this.http.get<any>('/pos/orders', { params: queryParams, observe: 'response' });
  }


  createOrUpdateOrder(order: OrderModel, queryParams: any = {}): Observable<OrderModel | undefined> {
    return order?.uuid ? this.updateOrder(order, queryParams) : this.createOrder(order, queryParams);
  }

  createOrder(order: OrderModel, queryParams: any = {}) {

    if (!this.onlineService.getIsOnlineValue()) {
      order.isSynced = false;
      return from(this.offlineStorage.createOrder(order)).pipe(
        catchError((e) => this.handleError(e, 'Unable to create order'))
      );
    }

    queryParams.expand = queryParams.expand || 'masterPayment.installments,order_items.addons.product_addon_id,coupon,table,customer';
    return this.http.post<OrderModel>('/pos/orders', order?.toApi(), { params: queryParams }).pipe(
      switchMap((response: OrderModel) => {
        order.isSynced = true;
        return from(this.offlineStorage.createOrder(new OrderModel(response)));
      }),
      catchError((e) => this.handleError(e, 'Unable to create order'))
    );
  }

  updateOrder(order: OrderModel, queryParams: any = {}) {

    if (!this.onlineService.getIsOnlineValue() || !order.id) {
      order.isSynced = false;
      return from(this.offlineStorage.updateOrder(order)).pipe(
        catchError((e) => this.handleError(e, 'Unable to update order'))
      );
    }

    queryParams.expand = queryParams.expand || 'masterPayment.installments,order_items.addons.product_addon_id,coupon,table,customer';
    return this.http.put<OrderModel>(`/pos/orders/${order?.id}`, order?.toApi(), { params: queryParams }).pipe(
      switchMap((response: OrderModel) => {
        order.isSynced = true;
        return from(this.offlineStorage.updateOrder(new OrderModel(response))).pipe(
          catchError((e) => this.handleError(e, 'Unable to update order'))
        );
      }),
      catchError((e) => this.handleError(e, 'Unable to update order'))
    );
  }

  getOrders(queryParams: OrderFilterInterface = {}): Observable<PaginatedDataInterface> {

    if (!this.onlineService.getIsOnlineValue()) {
      return from(this.offlineStorage.getPaginatedOrders(queryParams));
    }

    return this.getOrdersWithHeaders(queryParams).pipe(
      map((response: any) => {
        const pagination = ResponseHelper.extractPagination(response);
        const orders = response.body.map((item: any) => new OrderModel(item));

        return {
          data: orders,
          page: pagination.page,
          totalRecords: pagination.total,
          'per-page': pagination.pageSize
        }
      })
    );
  }

  updateOrders(orders: OrderModel[]): Observable<any> {
    if (!this.onlineService.getIsOnlineValue()) {
      return from(this.offlineStorage.bulkUpdateOrders(orders)).pipe(
        catchError(e => this.handleError(e, 'Unable to update orders')),
        map(() => orders)
      );
    }

    return this.http.put(`/pos/orders`, { orders: orders.map(order => order.toApi()) }).pipe(
      switchMap((res: any) => {
        const updatedOrders = res.orders?.map((order: any) => new OrderModel(order));

        return from(this.offlineStorage.bulkUpdateOrders(updatedOrders)).pipe(
          catchError(e => this.handleError(e, 'Unable to update orders')),
          map(() => updatedOrders)
        );
      })
    );
  }

  syncOrders(orders: OrderModel[], queryParams:any = {}): Observable<OrderModel[] | undefined> {
    queryParams.expand = queryParams.expand || 'masterPayment.installments,order_items.addons.product_addon_id,coupon,table,customer';
    const payload = orders.map((order: OrderModel) => order.toApi());
    return this.http.post<any>('/pos/sync', payload, { params: queryParams }).pipe(
      map((response: any) => {
        return response.map((item: any) => new OrderModel(item));
      }),
      catchError((e) => this.handleError(e, 'Unable to update order'))
    );
  }

  getOrdersFilterFromStorage(): OrderFilterInterface {
    const storedOrdersFilter = localStorage.getItem(this._ordersFilterStorageKey);
    if (!storedOrdersFilter) {
      return {};
    }

    return JSON.parse(storedOrdersFilter);
  }

  setOrdersFilterInStorage(filterParams: OrderFilterInterface): void {
    localStorage.setItem(this._ordersFilterStorageKey, JSON.stringify(filterParams));
  }

  private handleError(e: any, message: string) {
    this.toasterService.showDanger(e.error?.errors?.length ? e.error.errors.join('<br>') :  e.message, message);
    return of(undefined);
  }

}
