import { BaseModel } from './base.model';
import { ImageModel } from './image.model';
import { ImageHelper } from '../helpers/image.helper';
import { FloorplanObjectModel } from './floorplan-object.model';

export class FloorplanModel extends BaseModel {
  id: number;
  imageId: number;
  location: string;
  image: ImageModel;
  imageFile: File;
  floorplanObjects: FloorplanObjectModel[];
  defaultImageUrl = '';

  get childModels() {
    return {
      floorplanObjects: FloorplanObjectModel
    };
  }

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }

    this.floorplanObjects = this.floorplanObjects?.map((fpObject: any) => new FloorplanObjectModel(fpObject));
  }

  get unsafeApiAttributes() {
    return ['image', 'defaultImageUrl'];
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }
}
