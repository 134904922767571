import { BaseModel } from './base.model';
import { TableModel } from './table.model';

export class FloorplanObjectModel extends BaseModel {
  id: number;
  width: number;
  height: number;
  top: number;
  left: number;
  rotation: number;
  shape: string;
  color: string;
  table?: TableModel;
  get childModels() {
    return {
      table: TableModel
    };
  }

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

}
