import { BaseModel } from './base.model';
import { ImageModel } from './image.model';
import { ORDER_TYPE, ORDER_TYPES, OrderTypeInterface } from './globals';
import { ImageHelper } from '../helpers/image.helper';
import { AvailabilityHoursModel } from './availability-hours.model';

export class ProductModel extends BaseModel {

  static readonly TYPE_FOOD: string = 'food';
  static readonly TYPE_BEVERAGE: string = 'beverage';
  static readonly ORDER_TYPES = ORDER_TYPES;

  id: number;
  categoryId: number;
  imageId: number;
  name: string;
  description: string;
  addonsTitle: string;
  type: string;
  orderTypes: OrderTypesModel;
  price: number;
  maxAddon: number;
  isActive: boolean;
  isAvailable: boolean;
  image: ImageModel;
  category: CategoryModel;
  tags: TagModel[];
  addons: AddonModel[];
  availability: WeeklyAvailabilityModel;

  imageFile?: File;
  defaultImageUrl = '/assets/media/placeholders/placeholder-meal.png';

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? { image_file: model.imageFile } : this.imageFile ? { image_file: this.imageFile } : {}),
    };
  }

  get unsafeApiAttributes() {
    return ['id', 'defaultImageUrl', 'hasAddons', 'addonName', 'addonAmount', 'category', 'image'];
  }

  get initialValues() {
    return {
      tags: [],
      addons: [],
      isActive: true,
      isAvailable: true,
      type: ProductModel.TYPE_FOOD,
      availability: new WeeklyAvailabilityModel(),
      orderTypes: new OrderTypesModel(),
    };
  }

  get childModels() {
    return {
      tags: TagModel,
      addons: AddonModel,
      category: CategoryModel,
      availability: WeeklyAvailabilityModel,
      orderTypes: OrderTypesModel
    };
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }

  get typeFood() {
    return ProductModel.TYPE_FOOD;
  }

  get typeBeverage() {
    return ProductModel.TYPE_BEVERAGE;
  }

  get hasAddons(): boolean {
    return this.addons.length > 0;
  }

  set hasAddons(b: boolean) {
    if (!b) {
      this.addons = [];
    }
  }

  isAvailableForOrderType(orderType: string) {
    if (!this.orderTypes) {
      return true;
    }

    switch (orderType) {
      case ORDER_TYPE.inRestaurant: return !!this.orderTypes.hasInRestaurant;
      case ORDER_TYPE.takeaway: return !!this.orderTypes.hasTakeaway;
      case ORDER_TYPE.delivery: return !!this.orderTypes.hasDelivery;
      default: return false;
    }
  }

}

export class CategoryModel extends BaseModel {
  id: number;
  parentId: number;
  imageId: number;
  name: string;
  description: string;
  sort: number;
  level: number;
  posColor: string;
  image: ImageModel;
  imageFile: File;
  parent?: CategoryModel;
  products?: ProductModel[];
  availabilityHours?: AvailabilityHoursModel
  defaultImageUrl = '/assets/images/placeholder-meal.png';

  children?: CategoryModel[];

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get initialValues() {
    return {
      sort: 0,
      level: 0,
      children: [],
      products: []
    };
  }

  get childModels() {
    return {
      children: CategoryModel,
      parent: CategoryModel,
      products: ProductModel,
      availabilityHours: AvailabilityHoursModel
    };
  }

  get unsafeApiAttributes() {
    return ['image', 'defaultImageUrl'];
  }

  imageThumb(specs = ''): string {
    return ImageHelper.createUrl(this.image.id, specs);
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? { image_file: model.imageFile } : this.imageFile ? { image_file: this.imageFile } : {}),
    };
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }

  findSubCategoryById(categoryId: number): CategoryModel | undefined {
    if (this.id == categoryId) {
      return this;
    }

    if (this.children) {
      for (const category of this.children) {
        if (category.id == categoryId) {
          return category;
        } else {
          const subCategory = category.findSubCategoryById(categoryId);
          if (subCategory) {
            return subCategory;
          }
        }
      }
      return undefined;
    }
    return undefined;
  }

  getProductsForOrderType(orderType: string): ProductModel[] {
    return this.products?.filter((el: ProductModel) => el.isAvailableForOrderType(orderType)) || [];
  }
}

export class TagModel extends BaseModel {
  id: number;
  restaurantId: number;
  name: string;
  type: string;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get unsafeApiAttributes() {
    return [];
  }
}

export class AddonModel extends BaseModel {
  id: number;
  name: string;
  price: number;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get unsafeApiAttributes() {
    return [];
  }
}

export class WeeklyAvailabilityModel extends BaseModel {
  onMon: boolean;
  onTue: boolean;
  onWed: boolean;
  onThu: boolean;
  onFri: boolean;
  onSat: boolean;
  onSun: boolean;
}

export class OrderTypesModel extends BaseModel {
  hasDelivery: boolean;
  hasTakeaway: boolean;
  hasInRestaurant: boolean;
}
