import { Injectable, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToasterService {
  toasts: any[] = [];

  constructor(
    private toastr: ToastrService
  ) {
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showInfo(text: string, title: string = ''): void {
    this.toastr.info(text, title, { toastClass: 'ngx-toastr bg-info', timeOut: 5000 });
  }

  showWarning(text: string, title: string = ''): void {
    this.toastr.warning(text, title, { toastClass: 'ngx-toastr bg-warning', timeOut: 5000 });
  }

  showSuccess(text: string, title: string = ''): void {
    this.toastr.success(text, title, { toastClass: 'ngx-toastr bg-success', timeOut: 5000 });
  }

  showDanger(text: string, title: string = ''): void {
    this.toastr.error(text, title, { toastClass: 'ngx-toastr bg-danger', timeOut: 5000 });
  }
}
