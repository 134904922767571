import { BaseModel } from './base.model';
import { NumberHelper } from '../helpers/number.helper';
import { COUPON_TYPES } from './globals';

export class CouponModel extends BaseModel {
  id: number;
  name: string;
  code: string;
  value: number;
  type: string;
  paymentTypes: string;
  couponUrl: string;
  qrCodeUrl: string;
  isActive: boolean;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  getDiscountValue(total: number): number {
    switch (this.type) {
      case COUPON_TYPES.amount:
        return Math.min(this.value, total);
      case COUPON_TYPES.percentage:
        return NumberHelper.roundToTwoDecimals(NumberHelper.percentage(total, this.value));
      default: return 0;
    }
  }

  getPaymentTypes(): string[] {
    return this.paymentTypes?.split(',') || [];
  }
}

