import { Injectable } from '@angular/core';
import { OrdersService } from './orders.service';
import { PaymentService } from './payment.service';
import { OfflineStorageService } from './offline-storage.service';
import { OnlineService } from './online.service';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';
import { OrderModel } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  private chunkSize = 20;
  private syncInterval = 3600000;
  private syncSubscription: Subscription;
  private isSyncing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private onlineService: OnlineService,
    private ordersService: OrdersService,
    private paymentService: PaymentService,
    private offlineStorage: OfflineStorageService,
  ) {
  }

  initSync() {
    combineLatest([this.onlineService.isOnline(), this.authService.getCurrentUser()])
      .subscribe(([isOnline, currentUser]) => {
        if (isOnline && currentUser) {
          this.startSync();
        } else {
          this.stopSync();
        }
      });
  }

  isSyncing() {
    return this.isSyncing$.asObservable();
  }

  getIsSyncingValue() {
    return this.isSyncing$.value;
  }

  private startSync() {
    if (!this.syncSubscription || this.syncSubscription.closed) {
      this.sync();
      this.syncSubscription = interval(this.syncInterval).subscribe(() => {
        if (!this.getIsSyncingValue()) {
          this.sync();
        }
      });
    }
  }

  private stopSync() {
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
  }

  async sync() {
    this.isSyncing$.next(true);
    const totalOrderCount = await this.offlineStorage.getOrdersTotalCount();
    const totalOrderChunks = Math.ceil(totalOrderCount / this.chunkSize);

    for (let i = 0; i < totalOrderChunks; i++) {
      const offset = i * this.chunkSize;
      const ordersChunk = await this.offlineStorage.getOrdersChunk(this.chunkSize, offset);
      const ordersChunkModels = ordersChunk.map((order) => {
        const orderModel = new OrderModel(order);
        orderModel.setProductAddonIdsAsAddonId();
        return orderModel;
      });
      const syncedOrders = await lastValueFrom(this.ordersService.syncOrders(ordersChunkModels));

      await this.offlineStorage.bulkUpdateOrders(syncedOrders || []);
    }

    this.isSyncing$.next(false);
  }

}
