import { StringHelper } from '../helpers/string.helper';

export class BaseModel {
    [key: string]: any;

    constructor(attributes?: any) {
        this.setAttributes(this.initialValues);
        if (attributes) {
            this.setAttributes(attributes);
        }
    }

    get initialValues(): { [key: string]: any } {
        return {};
    }

    get childModels(): { [key: string]: any } {
        return {};
    }

    get unsafeApiAttributes() {
        return ['id', 'defaultImageUrl'];
    }

    get attributeMap(): { [key: string]: any } {
        return {};
    }

    setAttributes(input: any): any {
        Object.assign(this, this.keysToCamel(input));
        let attribute: string;

        for (attribute of Object.keys(this.childModels)) {
            const Model = this.childModels[attribute];
            let value = input[attribute];

            if (value !== undefined) {
                if (this.isArray(value)) {
                    value = value.map((item: any) => (new Model()).setAttributes(item));
                } else if (this.isObject(value)) {
                    value = value instanceof File ? value : (new Model()).setAttributes(value);
                }
                this[attribute] = value;
            }
        }

        return this;
    }

    toApi(model?: this) {
        const apiArray: { [key: string]: any } = {};
        const object = model || this;

        for (const [k, v] of Object.entries(object)) {
            const isSafeAttribute = this.isFunction(object.isSafeAttribute) ? object.isSafeAttribute(k) : true;
            if (v !== undefined && isSafeAttribute) {
                const Model = this.childModels[k];
                if (this.isArray(v)) {
                    apiArray[this.toUnderscore(k)] = v.map((item: any) => {
                        return Model !== undefined ? new Model(item).toApi() : this.toApi(item);
                    });
                } else if (this.isObject(v)) {
                    apiArray[this.toUnderscore(k)] = v instanceof File ? v : (Model !== undefined ? new Model(v).toApi() : this.toApi(v));
                } else {
                    if (v !== null && v !== undefined) {
                        apiArray[this.toUnderscore(k)] = v;
                    }
                }
            }
        }

        return apiArray;
    }

    asPlainObject() {
        return JSON.parse(JSON.stringify(this));
    }

    clone(): any {
        let clone = new (this.constructor as any);
        for (const [k, v] of Object.entries(this)) {
            const isSafeAttribute = this.isFunction(this.isSafeAttribute) ? this.isSafeAttribute(k) : true;
            if (isSafeAttribute) {
                clone[k] = v;
            }
        }
        return clone;
    }

    private toObjectKey(k: any) {
        return this.attributeMap[k] || this.toCamel(k);
    }

    private isSafeAttribute(attribute: string): boolean {
        return this.unsafeApiAttributes.indexOf(attribute) === -1;
    }

    private keysToCamel(o: any) {
        if (this.isObject(o)) {
            const n: { [key: string]: any } = {};

            Object.keys(o).forEach((k) => {
                n[this.toObjectKey(k)] = this.keysToCamel(o[k]);
            });

            return n;
        } else if (this.isArray(o)) {
            return o.map((i: any) => {
                return this.keysToCamel(i);
            });
        }

        return o;
    }

    private keysToUndercore(o: any) {
        if (this.isObject(o)) {
            const n: { [key: string]: any } = {};

            Object.keys(o).forEach((k) => {
                n[this.toUnderscore(k)] = this.keysToUndercore(o[k]);
            });

            return n;
        } else if (this.isArray(o)) {
            return o.map((i: any) => {
                return this.keysToUndercore(i);
            });
        }

        return o;
    }

    private toCamel(s: string) {
        return StringHelper.toCamel(s);
    }

    private toUnderscore(s: string) {
        return StringHelper.toUnderscore(s);
    }

    private isArray(a: any) {
        return Array.isArray(a);
    }

    private isObject(o: any) {
        return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
    }

    private isFunction(f: any) {
        return typeof f === 'function';
    }
}
