import { StringHelper } from "../helpers/string.helper";
import { BaseModel } from "./base.model";
import { UserModel } from "./user.model";

export class AuthModel extends BaseModel {
  authToken: string;
  refreshToken: string;
  expiresIn: Date;
  scope: any;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get attributeMap(): { [key: string]: any } {
    return {
      'access_token': 'authToken'
    };
  }
}

export class EncryptedAuthModel extends BaseModel {
  passcode: string;
  plainPasscode: string;
  user: UserModel;
  auth: AuthModel;

  get childModels() {
    return {
      auth: AuthModel,
      user: UserModel,
    }
  }

  get unsafeApiAttributes() {
    return ['plainPasscode'];
  }

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  getDecryptedAuthModel(passcode: string) {
    let authModel: AuthModel = new AuthModel();
    authModel.authToken = passcode ? StringHelper.decrypt(this.auth.authToken, passcode) : this.auth.authToken;
    authModel.refreshToken = passcode ? StringHelper.decrypt(this.auth.refreshToken, passcode) : this.auth.refreshToken;
    authModel.expiresIn = this.auth.expiresIn;
    authModel.scope = this.auth.scope;

    return authModel;
  }
}
