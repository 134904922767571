import * as CryptoJS from 'crypto-js';

export class StringHelper {

  static CryptoJSAes = {
    stringify: function (cipherParams: any) {
      let ct = cipherParams?.ciphertext?.toString(CryptoJS.enc.Base64);
      let iv = cipherParams?.iv?.toString();
      let salt = cipherParams?.salt?.toString();

      return `${ct}:${iv}:${salt}`;
    },
    parse: function (s: string) {
      var parts = s.split(':');
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(parts[0]) });
      if (parts[1]) cipherParams.iv = CryptoJS.enc.Hex.parse(parts[1]);
      if (parts[2]) cipherParams.salt = CryptoJS.enc.Hex.parse(parts[2]);

      return cipherParams;
    }
  }

  static toUnderscore(s: string) {
    return s.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  static toCamel(s: string) {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }

  static encrypt(decoded: string, key: string): string {
    return decoded ? CryptoJS.AES.encrypt(decoded, key, { format: StringHelper.CryptoJSAes }).toString() : '';
  }

  static decrypt(encoded: string, key: string): string {
    return encoded ? CryptoJS.AES.decrypt(encoded, key, { format: StringHelper.CryptoJSAes }).toString(CryptoJS.enc.Utf8) : '';
  }
}
