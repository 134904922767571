import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../../common/models/user.model';
import { environment } from '../../../../environments/environment';
import { AuthModel } from '../../../common/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`/oauth/token`, {
      username: email,
      password,
      grant_type: 'password',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }).pipe(
      map(response => {
        return new AuthModel(response);
      })
    );
  }

  unlock(pin: string): Observable<any> {
    return this.http.post<AuthModel>(`/user/unlock`, {
      pin: pin,
    }).pipe(
      map(response => {
        return new AuthModel(response);
      })
    );
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`/users/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`/users/me`, {
      headers: httpHeaders,
    });
  }

  refreshToken(token: string): any {
    return this.http.post<AuthModel>(`/oauth/token`, {
      refresh_token: token,
      grant_type: 'refresh_token',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    }).pipe(
      map((response: AuthModel) => {
        return new AuthModel(response);
      })
    );
  }

  resetPassword(password: string, passwordRepeat: string, token: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/users/reset-password`, {
      new_password: password,
      password_repeat: passwordRepeat,
      reset_token: token
    });
  }


}
