import { BaseModel } from './base.model';
import { AddonModel, ProductModel } from './product.model';
import { AddressModel } from './address.model';
import { UserModel } from './user.model';
import {
  CARD_BRANDS,
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  ORDER_STATUSES,
  ORDER_TYPE,
  ORDER_TYPES,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  PAYMENT_STATUSES
} from './globals';
import { TableModel } from './table.model';
import { CouponModel } from './coupon.model';
import { NumberHelper } from '../helpers/number.helper';
import { RestaurantModel } from './restaurant.model';

export class OrderModel extends BaseModel {
  id?: number;
  uuid: string;
  tableId?: number;
  tableCode?: string;
  address?: AddressModel;
  addressId?: number;
  restaurantId?: number;
  customer: UserModel;
  customerCode?: string;
  orderType: string;
  deliverAt: number;
  deliverAtTime: string;
  deliverAtDate: string;
  deliveryDate: string;
  deliveryTime: string;
  note?: string;
  orderAmount: number;
  tipAmount: number;
  itemAmount: number;
  totalAmount: number;
  additionalAmount: number;
  adjustmentAmount: number;
  deliveryAmount: number;
  discountAmount: number;
  totalTax?: number;
  createdAt: number;
  updatedAt: number;
  status: string;
  isPrinted: boolean;
  isSynced: boolean;
  canBeCanceled: boolean;
  isPartOfMultiOrder: boolean;
  couponCode?: string;

  table: TableModel;
  coupon: CouponModel;
  orderItems: OrderItem[];
  masterPayment: PaymentModel;
  restaurant: RestaurantModel;

  get childModels(): {} {
    return {
      orderItems: OrderItem,
      address: AddressModel,
      customer: UserModel,
      table: TableModel,
      masterPayment: PaymentModel,
      coupon: CouponModel,
      restaurant: RestaurantModel,
    };
  }

  get initialValues(): {} {
    return {
      orderAmount: 0,
      tipAmount: 0,
      itemAmount: 0,
      totalAmount: 0,
      additionalAmount: 0,
      adjustmentAmount: 0,
      discountAmount: 0,
      deliveryAmount: 0,
      orderItems: []
    };
  }

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }

    this.convertNumericAttributesToNumbers();
  }

  private convertNumericAttributesToNumbers() {
    this.getNumericAttributes().forEach((attribute) => {
      if (attribute in this) {
        this[attribute] = NumberHelper.convertToDecimal(this[attribute]);
      }
    });
  }

  private getNumericAttributes() {
    return ['orderAmount', 'tipAmount', 'itemAmount', 'totalAmount', 'additionalAmount', 'adjustmentAmount', 'deliveryAmount', 'discountAmount']
  }

  get unsafeApiAttributes(): string[] {
    return [
      'coupon', 'table', 'restaurant', 'defaultImageUrl'
    ];
  }

  get taxableAmount(): number {
    return NumberHelper.convertToDecimal(this.totalAmount - this.tipAmount);
  }

  get statusObj(): { key: string, value: string, color: string } | undefined {
    return ORDER_STATUSES.find(status => status.key === this.status);
  }

  get orderTypeObj(): { key: string, value: string, color: string } | undefined {
    return ORDER_TYPES.find(type => type.key === this.orderType);
  }

  get isTakeaway(): boolean {
    return this.orderType === ORDER_TYPE.takeaway;
  }

  get isDelivery(): boolean {
    return this.orderType === ORDER_TYPE.delivery;
  }

  get isInRestaurant(): boolean {
    return this.orderType === ORDER_TYPE.inRestaurant;
  }

  get isCanceled(): boolean {
    return this.status === ORDER_STATUS.canceled;
  }

  get isFinished(): boolean {
    return this.status === ORDER_STATUS.finished;
  }

  get isReady(): boolean {
    return this.status === ORDER_STATUS.ready;
  }

  get isAccepted(): boolean {
    return this.status === ORDER_STATUS.accepted;
  }

  get isPending(): boolean {
    return this.status === ORDER_STATUS.pending;
  }

  get createdAtDateObject() {
    if (!this.createdAt) {
      return null;
    }

    return new Date(this.createdAt * 1000);
  }

  get deliveryDateObject() {
    if (!this.deliverAtDate) {
      return null;
    }
    const date = this.deliverAtDate + (this.deliverAtTime ? 'T' + this.deliverAtTime : '');

    return new Date(date);
  }

  get masterPaymentStatusObj() {
    return this.masterPayment?.statusObj;
  }

  uuidPart() {
    return this.uuid.split('-')[0];
  }

  calculateTotals() {
    this.orderAmount = this.calculateOrderAmount();
    this.discountAmount = this.calculateDiscountAmount();
    this.totalAmount = this.calculateTotalAmount();
  }

  calculateOrderAmount() {
    let totalOrderAmount = 0;

    this.orderItems.forEach((item: OrderItem) => {
      item = new OrderItem(item);
      item.calculateTotals();
      totalOrderAmount += item.totalAmount;
    });

    return NumberHelper.convertToDecimal(totalOrderAmount || 0);
  }

  calculateTotalAmount() {
    return NumberHelper.convertToDecimal(this.orderAmount + this.tipAmount + this.adjustmentAmount + this.additionalAmount + this.deliveryAmount - this.discountAmount);
  }

  calculateDiscountAmount() {
    return NumberHelper.convertToDecimal(this.coupon ? this.coupon.getDiscountValue(this.orderAmount + this.additionalAmount + this.deliveryAmount) : 0);
  }

  changeCartItemsStatusTo(status: string, courseIndex?: number) {
    this.orderItems.forEach((orderItem: OrderItem, itemIndex) => {
      if ((courseIndex === undefined || orderItem.course == courseIndex) && orderItem.status == ORDER_ITEM_STATUS.cart) {
        this.orderItems[itemIndex].status = status;
      }
    });
  }

  getInstallmentsOrderPaidAmount() {
    return this.masterPayment?.getInstallmentsOrderPaidAmount() || 0;
  }

  getDueAmount() {
    return this.masterPayment ? this.masterPayment.getDueAmount() : this.totalAmount;
  }

  getVatPercent(): number {
    return NumberHelper.convertToDecimal(this.restaurant?.getOrderTypeByKey(this.orderType)?.vatPercent || 0);
  }

  setProductAddonIdsAsAddonId() {
    if (this.orderItems.length) {
      this.orderItems.forEach((item: OrderItem) => {
        if (item.addons?.length) {
          item.addons.forEach((addon: AddonModel, i: number) => {
            if (addon.productAddonId) {
              addon.id = { ...addon }.productAddonId;
              delete addon.productAddonId;
            }
          });
        }
      });
    }
  }
}

export class OrderItem extends BaseModel {
  id?: number;
  orderId: number;
  productId: number;
  paymentId: number;
  paymentUuid: string;
  name: string;
  type: string;
  course: number;
  note: string;
  status: string;
  quantity: number;
  unitPrice: number;
  addonAmount: number;
  totalAmount: number;
  createdAt: number;
  addons: AddonModel[];
  product: ProductModel;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get childModels(): {} {
    return {
      addons: AddonModel,
      product: ProductModel
    };
  }

  get unsafeApiAttributes(): string[] {
    return [];
  }

  get createdAtDateObject() {
    if (!this.createdAt) {
      return null;
    }

    return new Date(this.createdAt * 1000);
  }

  get isFinished(): boolean {
    return this.status === ORDER_ITEM_STATUS.finished;
  }

  get isReady(): boolean {
    return this.status === ORDER_ITEM_STATUS.ready;
  }

  get isPending(): boolean {
    return this.status === ORDER_ITEM_STATUS.pending;
  }

  getAddonTotalPrice() {
    return this.addons?.map((o) => NumberHelper.convertToDecimal(o.price)).reduce((a, b) => a + b, 0) || 0;
  }

  getItemAmount() {
    return NumberHelper.roundToTwoDecimals(this.quantity * this.unitPrice);
  }

  calculateTotals() {
    this.addonAmount = NumberHelper.roundToTwoDecimals(this.getAddonTotalPrice());
    this.totalAmount = NumberHelper.roundToTwoDecimals(this.quantity * (NumberHelper.convertToDecimal(this.unitPrice) + this.addonAmount));
  }
}

export class PaymentModel extends BaseModel {
  id: number;
  uuid: string;
  parentId: number;
  parentUuid: string;
  orderId: number;
  orderUuid: string;
  transactionId: string;
  subMerchantId: string;
  transactionInitTime: number;
  transactionTime: number;
  referenceNumber: string;
  paymentType: string;
  paymentMethod: string;
  currency: string;
  feeAmount: number;
  commissionAmount: number;
  restaurantAmount: number;
  vatPercent: number;
  tipAmount: number;
  totalAmount: number;
  authCode: string;
  acquirerAuthCode: string;
  paymentOptionNumber: string;
  paymentOptionBrand: string;
  responseCode: string;
  responseMessage: string;
  status: string;
  createdAt: number | undefined;
  createdBy: number | undefined;

  installments: PaymentModel[];
  order: OrderModel;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get childModels(): {} {
    return {
      order: OrderModel,
      installments: PaymentModel
    };
  }

  get unsafeApiAttributes(): string[] {
    return [];
  }

  get initialValues() {
    return {
      installments: [],
      commissionAmount: 0,
    };
  }

  get createdDateObject() {
    if (!this.createdAt) {
      return null;
    }

    return new Date(this.createdAt * 1000);
  }

  get transactionDateObject() {
    if (!this.transactionTime) {
      return null;
    }

    return new Date(this.transactionTime * 1000);
  }

  get isTodayTransaction() {
    if (!this.transactionDateObject) {
      return false;
    }

    const today = new Date();

    return today.toDateString() === this.transactionDateObject.toDateString();
  }

  get vatAmount(): number | null {
    if (!this.order) {
      return null;
    }

    return NumberHelper.getPercentage(this.order.taxableAmount, this.vatPercent);
  }

  get transactionInitDateObject() {
    if (!this.transactionInitTime) {
      return null;
    }

    return new Date(this.transactionInitTime * 1000);
  }

  get isCashPayment(): boolean {
    return this.paymentMethod === PAYMENT_METHOD.cash;
  }

  get isCreated(): boolean {
    return this.status === PAYMENT_STATUS.created;
  }

  get statusObj(): { key: string, value: string, color: string } | undefined {
    return PAYMENT_STATUSES.find(status => status.key === this.status);
  }

  get isPending(): boolean {
    return this.status === PAYMENT_STATUS.pending;
  }

  get isCaptured(): boolean {
    return this.status === PAYMENT_STATUS.captured;
  }

  get isAuthorised(): boolean {
    return this.status === PAYMENT_STATUS.authorised;
  }

  get isSettled(): boolean {
    return this.status === PAYMENT_STATUS.settled;
  }

  get isCanceled(): boolean {
    return this.status === PAYMENT_STATUS.canceled;
  }

  get isRefunded(): boolean {
    return this.status === PAYMENT_STATUS.refunded;
  }

  get isFailed(): boolean {
    return this.status === PAYMENT_STATUS.failed;
  }

  get isSettledOrInProcessing() {
    return [PAYMENT_STATUS.captured, PAYMENT_STATUS.authorised, PAYMENT_STATUS.settled].includes(this.status);
  }

  get canBeCanceled(): boolean {
    return this.isAuthorised || this.isPending || this.isCreated;
  }

  get isCapturedOrSettled(): boolean {
    return this.isCaptured || this.isSettled;
  }

  get canSettleCashPayment() {
    return this.isCashPayment && !this.isCanceled && !this.isRefunded && !this.isSettled;
  }

  get paymentMethodObj() {
    return this.paymentMethod ? PAYMENT_METHODS.find(item => item.key == this.paymentMethod) : null;
  }

  get paymentAmount() {
    return NumberHelper.convertToDecimal(this.totalAmount) - NumberHelper.convertToDecimal(this.tipAmount);
  }

  get itemTotal() {
    return NumberHelper.convertToDecimal(this.totalAmount || 0) - NumberHelper.convertToDecimal(this.tipAmount || 0);
  }

  uuidPart() {
    return this.uuid.split('-')[0];
  }

  getPaymentMethodLabelByKey() {
    return this.paymentMethod ? PAYMENT_METHODS.find(item => item.key == this.paymentMethod)?.value : null;
  }

  getPaymentMethodIcon() {
    if (this.paymentMethod == PAYMENT_METHOD.creditCard) {
      return this.getCreditCardIcon();
    }

    if (this.paymentMethod == PAYMENT_METHOD.cash) {
      return 'assets/media/svg/icons/Shopping/Money.svg';
    }

    return this.paymentMethod ? 'assets/datatrans-logos/' + this.paymentMethod + '.svg' : 'assets/media/svg/icons/Shopping/Box2.svg';
  }

  getCreditCardIcon() {
    const card = CARD_BRANDS.find(brand => brand.value === this.paymentOptionBrand)?.key;

    if (card) {
      return 'assets/datatrans-logos/' + card + '.svg';
    }

    return 'assets/media/svg/icons/Shopping/Credit-card.svg';
  }

  getDueAmount() {
    const totalAmount = NumberHelper.convertToDecimal(this.totalAmount);

    if (this.isSettledOrInProcessing || !totalAmount || totalAmount < this.getInstallmentsTotal()) {
      return 0;
    }

    return totalAmount - this.getInstallmentsTotal();
  }

  getInstallmentsOrderPaidAmount(): number {
    return this.installments.length ? this.installments.map(item =>
      NumberHelper.convertToDecimal(item.totalAmount) - NumberHelper.convertToDecimal(item.tipAmount))
      .reduce((prev, next) => prev + next) : 0;
  }

  getInstallmentsTotal() {
    return this.installments.length ? this.installments?.map(item => item.isSettledOrInProcessing ? NumberHelper.convertToDecimal(item.totalAmount) : 0).reduce((prev, next) => prev + next) : 0;
  }

  getAsInstallmentModel() {
    return new InstallmentModel({
      id: this.id,
      uuid: this.uuid,
      orderId: this.orderId,
      orderUuid: this.orderUuid,
      status: this.status,
      tipAmount: this.tipAmount,
      paymentAmount: this.paymentAmount,
      paymentMethod: this.paymentMethod,
      isSelected: false,
      payment: this
    });
  }

  addInstallment(installment: InstallmentModel) {
    if (installment.paymentAmount >= this.getDueAmount()) {
      if (this.installments.length) {
        this.pushInstallmentAndUpdateAmounts(installment);
      } else {
        this.paymentMethod = installment.paymentMethod;
        this.tipAmount = (this.tipAmount || 0) + installment.tipAmount;
        this.totalAmount = (this.totalAmount || 0) + installment.tipAmount;
      }
      this.createdAt = undefined;
      this.createdBy = undefined;
      this.transactionTime = this.getCurrentTimestamp();
      this.status = PAYMENT_STATUS.settled;
    } else {
      this.pushInstallmentAndUpdateAmounts(installment);
    }
  }

  private pushInstallmentAndUpdateAmounts(installment: InstallmentModel) {
    const payment = installment.payment?.id ? installment.payment : new PaymentModel({
      paymentType: 'credit',
      status: PAYMENT_STATUS.settled,
      parentId: this.id,
      parentUuid: this.uuid,
      orderId: this.orderId,
      orderUuid: this.orderUuid,
      currency: this.currency,
      vatPercent: this.vatPercent,
      uuid: installment.uuid,
      tipAmount: installment.tipAmount,
      totalAmount: installment.totalAmount,
      paymentMethod: installment.paymentMethod,
      transactionTime: this.getCurrentTimestamp(),
      updatedAt: installment.updatedAt,
      createdAt: installment.createdAt,
      updatedBy: installment.updatedBy,
      createdBy: installment.createdBy,
    });

    this.installments.push(payment);
    this.tipAmount = (this.tipAmount || 0) + installment.tipAmount;
    this.totalAmount = (this.totalAmount || 0) + installment.tipAmount;
  }

  private getCurrentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

}

export type SelectedItemType = {
  courseIndex: number;
  itemIndex: number;
}

export class InstallmentModel extends BaseModel {

  id: number;
  uuid: string;
  orderId?: number;
  orderUuid?: string;
  tipAmount: number;
  paymentAmount: number;
  paymentMethod: string;
  selectedItems: SelectedItemType[];

  dueAmount: number;
  changeAmount: number;
  receivedAmount: number;

  isChangeAmountTip: boolean;
  isSelected: boolean;

  payment: PaymentModel;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get childModels(): {} {
    return {
      payment: PaymentModel
    };
  }

  get initialValues() {
    return {
      dueAmount: 0,
      paymentAmount: 0,
      receivedAmount: 0,
      tipAmount: 0,
      changeAmount: 0,
      selectedItems: []
    };
  }

  get isPayable() {
    return !this.payment?.status || this.payment?.status == PAYMENT_STATUS.created;
  }

  get totalAmount() {
    return NumberHelper.convertToDecimal(this.paymentAmount) + NumberHelper.convertToDecimal(this.tipAmount);
  }

  calculateChangeAndTipAmount() {
    if (this.isChangeAmountTip) {
      this.changeAmount = 0;
      this.tipAmount = this.receivedAmount - this.paymentAmount;
    } else {
      this.changeAmount = this.receivedAmount - (this.paymentAmount + this.tipAmount);
    }
  }
}

