import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { locale as itLang } from './modules/i18n/vocabs/it';
import { locale as srLang } from './modules/i18n/vocabs/sr';
import { ThemeModeService } from './_layout/components/theme-mode-switcher/theme-mode.service';
import { ToasterService } from './common/services/toaster.service';
import { OnlineService } from './common/services/online.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private onlineService: OnlineService,
    private toaster: ToasterService,
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      deLang,
      frLang,
      itLang,
      srLang
    );
  }

  ngOnInit() {
    this.modeService.init();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    if (!this.onlineService.getIsOnlineValue())  {
      event.preventDefault();
    }
  }
}
