// Italy
export const locale = {
  lang: 'it',
  data: {
    TRANSLATOR: {
      SELECT: 'Seleziona Lingua',
    },
    MENU: {
      REGISTER: 'Registrati',
      NEW: 'Nuovo',
      ACTIONS: 'Azioni',
      CREATE_POST: 'Crea Nuovo Post',
      PAGES: 'Pagine',
      FEATURES: 'Funzionalità',
      APPS: 'App',
      DASHBOARD: 'Cruscotto',
    },
    AUTH: {
      GENERAL: {
        OR: 'Oppure',
        SUBMIT_BUTTON: 'Invia',
        NO_ACCOUNT: 'Nessun account?',
        SIGNUP_BUTTON: 'Registrati',
        FORGOT_BUTTON: 'Password dimenticata',
        BACK_BUTTON: 'Indietro',
        PRIVACY: 'Privacy',
        LEGAL: 'Legale',
        CONTACT: 'Contattaci',
        TITLE: 'Veloce, efficiente e produttivo',
        DESCRIPTION: 'Semplifica le operazioni del tuo ristorante per ordini, asporto e consegne. Dalla creazione degli ordini alla fatturazione, rendi il lavoro del tuo team più facile e migliora la soddisfazione dei clienti con la nostra soluzione completa.'
      },
      LOGIN: {
        TITLE: 'Accedi',
        BUTTON: 'Accedi',
      },
      FORGOT: {
        TITLE: 'Hai dimenticato la password?',
        DESC: 'Inserisci il tuo indirizzo email per reimpostare la tua password',
        SUCCESS: 'Il tuo account è stato reimpostato con successo.'
      },
      REGISTER: {
        MAX_NUM_ADDONS: 'Puoi selezionare al massimo {{maxAddons}} aggiunte',
        ANY_NUM_ADDONS: 'Puoi selezionare un numero qualsiasi di aggiunte'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nome Completo',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Conferma Password',
        USERNAME: 'Nome Utente'
      },
      CART: {
        VAT_PERCENT_LABEL: 'IVA inclusa ({{vatPercent}}%)'
      },
      VALIDATION: {
        INVALID: '{{name}} non è valido',
        REQUIRED: '{{name}} è richiesto',
        MIN_LENGTH: 'Lunghezza minima di {{name}} è {{min}} caratteri',
        AGREEMENT_REQUIRED: 'È necessaria l\'accettazione dei termini',
        NOT_FOUND: '{{name}} non trovato',
        INVALID_LOGIN: 'Le credenziali di accesso non sono corrette',
        REQUIRED_FIELD: 'Campo richiesto',
        MIN_LENGTH_FIELD: 'Lunghezza minima del campo:',
        MAX_LENGTH_FIELD: 'Lunghezza massima del campo:',
        INVALID_FIELD: 'Campo non valido',
      }
    },
    CHECKOUT: {
      SPLIT_PAYMENT: {
        DUE_LABEL: "L'importo da pagare per il pagamento suddiviso è <strong>{{dueAmount}}</strong>.",
        AMOUNT_LABEL: "Ogni persona paga un importo uguale di <strong>{{amount}}</strong>.",
        MESSAGE: "L'importo da pagare per il pagamento suddiviso di <strong>{{dueAmount}}</strong> tra <strong>{{num}}</strong> persone. Ogni persona paga un importo uguale di <strong>{{amount}}</strong>."
      }
    },
    SETTINGS: {
      MENU_DATA: {
        TITLE: "Aggiorna i Dati del Menu",
        DESCRIPTION: "Aggiorniamo regolarmente gli articoli del tuo ristorante affinché il sistema POS sia sincronizzato con il tuo menu e funzioni in modo autonomo. L'ultimo aggiornamento è stato",
      },
      SYNCHRONIZE: {
        TITLE: "Sincronizza Dati Locali",
        DESCRIPTION: "Sincronizziamo i dati locali con il server ogni pochi minuti. Puoi avviare manualmente la sincronizzazione facendo clic su questo pulsante. L'ultima sincronizzazione è stata",
      }
    },
    "Unlock POS": "Sblocca POS",
    "Terms": "Termini",
    "Plans": "Piani",
    "Contact Us": "Contattaci",
    "1/2 of bill": "1/2 fattura",
    "1/3 of bill": "1/3 fattura",
    "1/4 of bill": "1/4 fattura",
    "ASAP": "Il prima possibile",
    "Action": "Azione",
    "Add a Bixolon Web printer": "Aggiungi una stampante Web Bixolon",
    "Add a printer:": "Aggiungi una stampante:",
    "Test print": "Stampa di prova",
    "Printer": "Stampante",
    "Line Width": "Larghezza della linea",
    "Selected printer already saved": "Stampante già salvata",
    "Printer is not connected": "Stampante non connessa",
    "Adjustment: ": "Regolazione:",
    "Any": "Qualsiasi",
    "Bixolon Web Print": "Stampa Web Bixolon",
    "CART": {
      "VAT_PERCENT_LABEL": "IVA inclusa ({{vatPercent}}%)"
    },
    "Cancel": "Annulla",
    "Continue": "Continua",
    "Coupon: ": "Buono:",
    "Creation Time": "Tempo di Creazione",
    "Customer": "Cliente",
    "Decrease": "Riduci",
    "Delivery Time": "Tempo di Consegna",
    "Device": "Dispositivo",
    "Dinego, your sales partner": "Dinego, il tuo partner per le vendite",
    "Choose user": "Scegli utente",
    "Select user to unlock Dinego POS, and start your shift": "Seleziona un utente per sbloccare Dinego POS e avviare il tuo turno",
    "Due": "Dovuto",
    "Email": "Email",
    "Password": "Password",
    "Enter your email to reset your password": "Inserisci la tua email per reimpostare la tua password",
    "Forgot Password?": "Password dimenticata?",
    "Email is required": "Email è obbligatoria",
    "Email is invalid": "Email non valida",
    "Email should have at least 3 symbols": "L'email dovrebbe avere almeno 3 caratteri",
    "Email should have maximum 360 symbols": "L'email dovrebbe avere al massimo 360 caratteri",
    "Password is required": "Password è obbligatoria",
    "Password is invalid": "Password non valida",
    "Password should have at least 3 symbols": "La password dovrebbe avere almeno 3 caratteri",
    "Password should have maximum 100 symbols": "La password dovrebbe avere al massimo 100 caratteri",
    "Full Name": "Nome completo",
    "Full Name is required": "Il nome completo è obbligatorio",
    "Full Name should have at least 3 symbols": "Il nome completo deve contenere almeno 3 caratteri",
    "Full Name should have maximum 100 symbols": "Il nome completo deve contenere al massimo 100 caratteri",
    "Please enter both name and surname": "Inserisci sia il nome che il cognome",
    "Phone": "Telefono",
    "Phone number is required": "Il numero di telefono è obbligatorio",
    "Phone number should have at least 3 symbols": "Il numero di telefono deve contenere almeno 3 caratteri",
    "Phone number should have maximum 100 symbols": "Il numero di telefono deve contenere al massimo 100 caratteri",
    "Please enter customer information": "Si prega di inserire le informazioni del cliente",
    "General": "Generale",
    "IP Address": "Indirizzo IP",
    "IP Address is required": "Indirizzo IP è obbligatorio",
    "Including Tip": "Incluso Servizio",
    "Increase": "Aumenta",
    "Language": "Lingua",
    "Add a printer using one of above methods": "Aggiungi una stampante utilizzando uno dei metodi sopra",
    "List of saved printers": "Elenco delle stampanti salvate",
    "Load to cart": "Aggiungi al carrello",
    "Location": "Posizione",
    "Lock": "Blocca",
    "Log Out": "Esci",
    "Logical name": "Nome logico",
    "Logical name is required": "Nome logico è obbligatorio",
    "Logout": "Esci",
    "Mine only": "Solo miei",
    "Miscellaneous": "Varie",
    "N/A": "Non disponibile",
    "No connected printers": "Nessuna stampante connessa",
    "OK": "OK",
    "Order #": "Ordine #",
    "Order Status": "Stato dell'Ordine",
    "Order Type": "Tipo di Ordine",
    "Orders": "Ordini",
    "Outstanding": "In sospeso",
    "POS Language": "Lingua POS",
    "POS Theme": "Tema POS",
    "Payment #": "Pagamento #",
    "Payment Method": "Metodo di Pagamento",
    "Payment Status": "Stato del Pagamento",
    "Payment Time": "Tempo di Pagamento",
    "Payment adjustment": "Regolazione del pagamento",
    "Payment amount": "Importo del pagamento",
    "Payments": "Pagamenti",
    "Pickup Time": "Tempo di Ritiro",
    "Please enter a valid number": "Inserisci un numero valido",
    "Please wait": "Attendere prego",
    "Port number": "Numero di porta",
    "Port number is required": "Numero di porta è obbligatorio",
    "Preview order": "Anteprima ordine",
    "Print order": "Stampa ordine",
    "Print receipt": "Stampa scontrino",
    "Printer configuration": "Configurazione stampante",
    "Receipts": "Scontrini",
    "Reference #:": "Riferimento #:",
    "Results per page": "Risultati per pagina",
    "Save": "Salva",
    "Search coupons": "Cerca buoni",
    "Coupon": "Buono",
    "Search tables": "Cerca tavoli",
    "Select coupon to apply": "Seleziona il buono da applicare",
    "Select table": "Seleziona tavolo",
    "Sent password reset, please check your email": "Reimpostazione della password inviata, controlla la tua email",
    "Settings": "Impostazioni",
    "Data synchronization": "Sincronizzazione dei dati",
    "Update menu data": "Aggiorna i dati del menu",
    "Synchronise local data": "Sincronizza i dati locali",
    "Sign in": "Accedi",
    "Sorry, looks like there are some errors detected, please try again": "Spiacente, sembra che ci siano degli errori, per favore riprova",
    "Split payment equally": "Dividi il pagamento equamente",
    "Submit": "Invia",
    "Sync now": "Sincronizza ora",
    "Syncing": "Sincronizzazione",
    "Table": "Tavolo",
    "Tables": "Tavoli",
    "The login details are incorrect": "Le credenziali di accesso non sono corrette",
    "Theme": "Tema",
    "There are no active coupons for your restaurant": "Non ci sono buoni attivi per il tuo ristorante",
    "There are no active tables for your restaurant": "Non ci sono tavoli attivi per il tuo ristorante",
    "There are no coupons matching your search": "Non ci sono buoni che corrispondono alla tua ricerca",
    "There are no orders matching your search": "Non ci sono ordini che corrispondono alla tua ricerca",
    "There are no receipts matching your search": "Non ci sono scontrini che corrispondono alla tua ricerca",
    "There are no tables matching your search": "Non ci sono tavoli che corrispondono alla tua ricerca",
    "Tip: ": "Suggerimento: ",
    "Today": "Oggi",
    "Update application language by choosing your preferred language": "Aggiorna la lingua dell'applicazione scegliendo la tua lingua preferita",
    "Update application skin color to dark or light, or use system preferred": "Aggiorna il colore della pelle dell'applicazione a scuro o chiaro, oppure utilizza il colore preferito dal sistema",
    "Update now": "Aggiorna ora",
    "You don't have any floorplans created yet": {
      " Please go to your admin panel and create some": {
        "": "Non hai ancora creato alcun piano. Per favore, vai al pannello di amministrazione e creane alcuni"
      }
    },
    "discount": "Sconto",
    "discount on entire order": "Sconto su tutto l'ordine",
    "out of": "su",
    "Direct sale": "Vendita diretta",
    "No table": "Nessun tavolo",
    "Search for products or categories": "Cerca prodotti o categorie",
    "There are no categories or products matching your search": "Non ci sono categorie o prodotti che corrispondono alla tua ricerca",
    "Product addons": "Addon prodotto",
    "Add course": "Aggiungi portata",
    "Total": "Totale",
    "Clear order": "Cancella ordine",
    "Item amount": "Importo articolo",
    "Add Adjustment": "Aggiungi Regolazione",
    "Update Adjustment": "Aggiorna Regolazione",
    "Bill adjustment": "Regolazione fattura",
    "Delivery cost": "Costo consegna",
    "Service fee": "Spese di servizio",
    "Tip amount": "Importo mancia",
    "Paid amount": "Importo pagato",
    "Total Due": "Totale dovuto",
    "Total paid": "Totale pagato",
    "Remove Course": "Rimuovi portata",
    "Apply Coupon": "Applica buono",
    "Remove Coupon": "Rimuovi buono",
    "Fire Order": "Invia ordine",
    "Fire Course": "Invia portata",
    "Remove Item": "Rimuovi articolo",
    "Order Amount": "Importo ordine",
    "Coupon Amount": "Importo buono",
    "Adjustment Amount": "Importo regolazione",
    "Tip Amount": "Importo mancia",
    "VAT Amount": "Importo IVA",
    "VAT Number": "Numero IVA",
    "Payment Receipt": "Ricevuta di pagamento",
    "Total Paid": "Totale pagato",
    "Thank you": "Grazie",
    "Not paid": "Non pagato",
    "Current orders": "Ordini attuali",
    "Order archive": "Archivio ordini",
    "Order": "Ordine",
    "Update Order": "Aggiorna Ordine",
    "Print Receipt": "Stampa ricevuta",
    "Transfer order": "Trasferisci ordine",
    "Clear table": "Pulisci tavolo",
    "Print receipts": "Stampa ricevute",
    "Transfer orders": "Trasferisci ordini",
    "New order": "Nuovo ordine",
    "There are no archive orders for this table": "Non ci sono ordini archiviati per questo tavolo",
    "There are no active orders for this table": "Non ci sono ordini attivi per questo tavolo",
    "Pay": "Paga",
    "Tip": "Mancia",
    "Payment method": "Metodo di pagamento",
    "Simply click on the button displaying the current value to set a custom amount": "Fai clic semplicemente sul pulsante che mostra l'importo attuale per impostare un importo personalizzato",
    "Customize the tip, or set it manually to your preference": "Personalizza la mancia o impostala manualmente secondo le tue preferenze",
    "Adjust received amount by setting a custom value with a simple click": "Regola l'importo ricevuto impostando un valore personalizzato con un semplice clic",
    "This is change that need to be returned to a customer, based on how much he is paying and how.": "Questo è il resto che deve essere restituito a un cliente, in base a quanto sta pagando e come.",
    "Keep change as tip": "Tieni il resto come mancia",
    "Received amount": "Importo ricevuto",
    "Change": "Resto",
    "Split": "Dividi",
    "Bill": "Fattura",
    "Checkout": "Pagamento",
    "Paid At": "Pagato il",
    "Paid With": "Pagato con",
    "Ordered At": "Ordinato il",
    "Dine-In": "Mangiare in loco",
    "Takeaway": "Da asporto",
    "Delivery": "Consegna",
    "This week": "Questa settimana",
    "Last week": "Settimana scorsa",
    "This month": "Questo mese",
    "Last month": "Mese scorso",
    "Custom range": "Intervallo personalizzato",
    "Administrator": "Amministratore",
    "Restaurant manager": "Responsabile ristorante",
    "Employee": "Dipendente",
    "Unknown": "Sconosciuto",
    "Created": "Creato",
    "Pending": "In sospeso",
    "Accepted": "Accettato",
    "Ready": "Pronto",
    "Finished": "Completato",
    "Authorised": "Autorizzato",
    "Captured": "Catturato",
    "Settled": "Regolato",
    "Canceled": "Annullato",
    "Refunded": "Rimborsato",
    "Failed": "Fallito",
    "Cash": "Contante",
    "Credit Card": "Carta di credito",
    "Apple pay": "Apple Pay",
    "Google pay": "Google Pay",
    "Twint": "Twint",
    "Clear": "Pulisci",
    "Apply": "Applica",
    "Course": "Portata"
  }
};
