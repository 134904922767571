export class NumberHelper {
    static roundToTwoDecimals(numb: number): number {
        return Math.round((numb + Number.EPSILON) * 100) / 100;
    }

    static percentage(value: number, percent: number): number {
        return value * (percent / 100);
    }

    static convertToNumber(value: any): number {
        if (!value) {
            return 0;
        }

        const isString = (typeof value === 'string' || value instanceof String);
        const isNumber = (typeof value === 'number' || value instanceof Number);

        return isString || isNumber ? Number(value) : 0;
    }

    static convertToDecimal(value: any, decimals: number = 2): number {
        const number = NumberHelper.convertToNumber(value);
        const multiplier = Math.pow(10, decimals);

        return Math.round(number * multiplier) / multiplier;
    }

    static getRecommendedTipAmounts(amount: number, length: number = 4) {
        let roundedAmounts: number[] = [];
        let roundingScales: number[] = [];

        if (amount < 200) {
            roundingScales = [0.50, 1.00, 1.50, 2.00, 5.00, 10.00, 15.00, 20.00, 30.00, 50.00];
        } else if (amount < 500) {
            roundingScales = [0.50, 1.00, 2.00, 5.00, 10.00, 15.00, 20.00, 30.00, 50.00, 100.00];
        } else if (amount < 1000) {
            roundingScales = [0.50, 1.00, 2.00, 5.00, 10.00, 20.00, 30.00, 50.00, 100.00, 150.00];
        } else {
            roundingScales = [1.00, 2.00, 5.00, 10.00, 15.00, 20.00, 50.00, 100.00, 150.00, 200.00];
        }

        for (const scale of roundingScales) {
            const roundedAmount = Math.ceil(amount / scale) * scale;
            const tipAmount = roundedAmount - amount;

            if (tipAmount && !roundedAmounts.includes(tipAmount)) {
                roundedAmounts.push(tipAmount);
            }

            if (roundedAmounts.length >= length) {
                return roundedAmounts;
            }
        }

        return roundedAmounts;
    }

    static getRecommendedPaymentAmounts(amount: number, length: number = 4, includeOriginalAmount: boolean = false) {
        const billDenominations = [5, 10, 20, 50, 100, 200, 500];
        const recommendations: number[] = [];
        let minimumBillCount = undefined;
        let lastMultiple: number = 0;
        let higherMultiples: number[] = [];

        if (includeOriginalAmount) {
            recommendations.push(amount);
        }

        for (const denomination of billDenominations) {
            higherMultiples = [];
            for (let i = 0; i <= 2; i++) {
                higherMultiples.push(NumberHelper.convertToDecimal(Math.ceil(amount / denomination) * denomination + i * denomination));
            }

            if (!lastMultiple || lastMultiple % denomination != 0) {
                for (lastMultiple of higherMultiples) {
                    if (lastMultiple > amount && !recommendations.includes(lastMultiple)) {
                        const billCount = NumberHelper.getMinimumBillsCount(lastMultiple, billDenominations);
                        if (minimumBillCount == undefined || (billCount != undefined && billCount <= minimumBillCount)) {
                            minimumBillCount = billCount;
                            recommendations.push(lastMultiple);
                            break;
                        }
                    }
                }
            }

            if (recommendations.length >= length) {
                break;
            };
        }

        for (let denomination of billDenominations) {
            denomination = NumberHelper.convertToDecimal(denomination);
            if (denomination > amount && !recommendations.includes(denomination)) {
                recommendations.push(denomination);
            }
            if (recommendations.length >= length) {
                break;
            };
        }

        return recommendations.slice(0, length);
    }

    static getMinimumBillsCount(amount: number, denominations: number[]): number | undefined {

        const billDenominations = [...denominations].sort((a, b) => b - a);

        let remainingAmount = amount;
        let billCount = 0;

        for (let i = 0; i < billDenominations.length; i++) {
            while (remainingAmount >= billDenominations[i]) {
                remainingAmount -= billDenominations[i];
                billCount++;
            }

            if (remainingAmount === 0) {
                break;
            }
        }

        if (remainingAmount !== 0) {
            return undefined;
        }

        return billCount;
    }

    static getPercentage(amount: number, percent: number) {
        return amount > 0 ? amount - (amount / (1 + percent / 100)) : 0;
    }
}