import { AddressModel } from './address.model';
import { OrderTypeModel } from './order-type.model';
import { ImageModel } from './image.model';
import { CompanyModel } from './company.model';
import { WorkingHoursModel } from './working-hours.model';
import { BaseModel } from './base.model';
import { ImageHelper } from '../helpers/image.helper';
import { UserModel } from './user.model';
import { TableModel } from './table.model';
import { CategoryModel } from './product.model';
import { DeliveryRuleModel } from './delivery-rule.model';
import { FloorplanModel } from './floorplan.model';

export class RestaurantModel extends BaseModel {
  id: number;
  companyId: number;
  company?: CompanyModel;
  addressId: number;
  preparationTime?: number;
  deliveryTime?: number;
  name: string;
  description?: string;
  email?: string;
  phone?: string;
  currency?: string;
  contactName?: string;
  isActive?: 0 | 1;
  logoId?: number;
  logoFile?: File;
  coverFile?: File;
  coverId?: number;
  address?: AddressModel;
  orderTypes?: OrderTypeModel[];
  employees?: UserModel[];
  tables?: TableModel[];
  menu: CategoryModel[];
  workingHours?: WorkingHoursModel;
  logo: ImageModel;
  cover: ImageModel;
  deliveryRules: DeliveryRuleModel[];
  floorplans: FloorplanModel[];
  defaultLogoUrl = '/assets/media/placeholders/placeholder-restaurant.jpg';
  defaultCoverUrl = '/assets/media/placeholders/placeholder-restaurant-cover.jpg';
  fetchedAt?: Date

  get initialValues() {
    return {
      tables: [],
      menu: [],
      employees: [],
      deliveryRules: [],
      orderTypes: []
    };
  }

  get childModels() {
    return {
      tables: TableModel,
      logo: ImageModel,
      cover: ImageModel,
      menu: CategoryModel,
      address: AddressModel,
      orderTypes: OrderTypeModel,
      employees: UserModel,
      deliveryRules: DeliveryRuleModel,
      floorplans: FloorplanModel
    }
  }

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get logoUrl() {
    if (this.logo && this.logo.id) {
      return ImageHelper.createUrl(this.logo.id, 'w150');
    }
    return this.defaultLogoUrl;
  }

  get coverUrl() {
    if (this.cover && this.cover.id) {
      return ImageHelper.createUrl(this.cover.id, 'w300');
    }
    return this.defaultCoverUrl;
  }

  getOrderTypeByKey(key: string): OrderTypeModel | undefined {
    return this.orderTypes?.find(o => o.orderType === key);
  }

  getDeliveryRuleForZipcode(zipcode: string): DeliveryRuleModel | undefined {
    return this.deliveryRules.find((rule: DeliveryRuleModel) => rule.zipcode === zipcode);
  }

  getMainMenuCategories(): CategoryModel[] {
    let categories: CategoryModel[] = [];

    this.menu?.forEach((category: CategoryModel) => {
      categories.push(category);
    });

    return categories;
  }

}

