import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { ROLE, ROLES } from './globals';

function _(t: string) {
  return t;
} //placeholed until '@biesbjerg/ngx-translate-extract-marker'; is added

export class UserModel extends BaseModel {
  id: number;
  username: string;
  password: string;
  email: string;
  pic: string;
  role: string;
  currency: string;
  isActive: 0 | 1;
  companyName: string;
  phone: string;
  address?: AddressModel;
  // personal information
  firstName: string;
  imageFile?: File;
  lastName: string;
  // account information
  language: string;
  timeZone: string;
  restaurantId?: number;

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.firstName = user.first_name || '';
    this.lastName = user.last_name || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.role = user.role || '';
    this.address = user.address;
    this.language = user.language;
    this.userId = user.user_id;
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? { image_file: model.imageFile } : {}),
    };
  }

  get fullname(): string {
    return (!!this.firstName ? this.firstName + ' ' : '') + (!!this.lastName ? this.lastName : '');
  }

  get roleLabel(): string {
    switch (this.role) {
      case ROLE.admin: return _('Administrator');
      case ROLE.manager: return _('Restaurant manager');
      case ROLE.staff: return _('Employee');
      default: return _('Unknown')
    }
  }

  get isAdmin(): boolean {
    return this.role === ROLE.admin;
  }

  get isManager(): boolean {
    return this.role === ROLE.manager;
  }

  get isStaff(): boolean {
    return this.role === ROLE.staff;
  }

  get isCustomer(): boolean {
    return this.role === ROLE.customer;
  }

  get RESTAURANT_ROLES() {
    if (this.isManager || this.isAdmin) {
      return ROLES.filter((role: any) => role.value !== ROLE.admin && role.value !== ROLE.customer);
    }
    if (this.isStaff) {
      return ROLES.filter((role: any) => role.value !== ROLE.admin && role.value !== ROLE.manager && role.value !== ROLE.customer);
    }
  }

}
