import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OnlineService {
    private isOnline$ = new BehaviorSubject<boolean>(window.navigator.onLine);

    constructor() {
        this.listenToOnlineStatus();
    }

    private listenToOnlineStatus(): void {
        window.addEventListener('online', () => this.isOnline$.next(true));
        window.addEventListener('offline', () => this.isOnline$.next(false));
    }

    isOnline() {
        return this.isOnline$.asObservable();
    }

    getIsOnlineValue() {
        return this.isOnline$.value;
    }
}