import { BaseModel } from './base.model';
import { ORDER_TYPES, PAYMENT_METHODS } from './globals';

export class OrderTypeModel extends BaseModel {
  id: number;
  orderType: string;
  additionalCost: number;
  vatPercent: number;
  isActive?: boolean;
  paymentMethods: PaymentMethodModel[];

  constructor(attributes?: any) {
    super();

    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get initialValues() {
    return {
      isActive: false,
      additionalCost: 0,
      paymentMethods: [],
    };
  }

  get childModels() {
    return {
      paymentMethods: PaymentMethodModel,
    }
  }

  get activePaymentMethods() {
    return this.paymentMethods?.filter((o: PaymentMethodModel) => o.isActive) || [];
  }

  get typeLabel() {
    return this.getObject()?.value || '';
  }

  get typeIcon() {
    return this.getObject()?.icon || 'xmark';
  }

  getObject() {
    return ORDER_TYPES.find((item: any) => item.key == this.orderType);
  }

  getPaymentMethodByKey(key: string) {
    return this.paymentMethods.find(o => o.paymentMethod === key);
  }

  getPaymentMethodLabelByKey(key: string) {
    const method = PAYMENT_METHODS.find((item: any) => item.key == key);
    return method?.value;
  }
}

export class PaymentMethodModel extends BaseModel {
  id: number;
  paymentMethod: string;
  isActive?: boolean;

  get initialValues() {
    return {
      isActive: false,
    };
  }

  get methodLabel() {
    const method = PAYMENT_METHODS.find((item: any) => item.key == this.paymentMethod);
    return method?.value || '';
  }

  get methodIcon() {
    const method = PAYMENT_METHODS.find((item: any) => item.key == this.paymentMethod);
    return method?.icon;
  }
}
