import { PaginationStateInterface } from "../interfaces/pagination.interface";

export class ResponseHelper {

    static extractPagination(response: any): PaginationStateInterface {

        const headers = response.headers;

        return {
            pageCount: parseInt(headers.get('X-Pagination-Page-Count'), 10),
            pageSize: parseInt(headers.get('X-Pagination-Per-Page'), 10),
            page: parseInt(headers.get('X-Pagination-Current-Page'), 10),
            total: parseInt(headers.get('X-Pagination-Total-Count'), 10)
        }
    }

}